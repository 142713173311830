import React from "react";
import BannerImage from "../Assets/home-banner-image.png";
import Navbar from "./Navbar";
import { IoMdArrowDropright } from "react-icons/io";


const Home = () => {
  return (
    <div className="home-container">
     
      <Navbar />
      <div className="home-banner-container">
       
      <div className="home-text-section">
          <h1 className="primary-heading-home">
          Active citizenry begins with our app!
          </h1>
          <p className="primary-text-home">
          Streamlining the process of reporting incidents in your area, all from the comfort of your home. No more waiting in long queues—get the help you need now!

          </p>
          <div className="home-btn-section">
          <button  onClick={()=>{
            window.open("https://play.google.com/store/apps/details?id=za.net.letstalk&hl=en_AU", "_blank");
          }}
          className="download-button">
            Download App 
          </button>
          <div style={{marginLeft:"5px"}}></div>
          <button
          onClick={()=>{
            window.open("https://citizen.justletstalk.com/", "_blank");
          }}
          className="download-button">
           WebApp 
          </button>
          <div style={{marginLeft:"5px"}}></div>
          <button
          onClick={()=>{
            window.open("https://youtu.be/hSjInU0s_vI?si=T7D9bzuHFW2zB_eI", "_blank");
          }}
          className="primary-button">
          <IoMdArrowDropright /> Watch Video 
          </button>
          </div>
        </div>

        <div className="home-image-section">
          <img src={BannerImage} alt="" />
        </div>
       
      </div>
    </div>
  );
};

export default Home;
