import React from "react";
import ProfilePic from "../Assets/john-doe-image.png";
import { AiFillStar } from "react-icons/ai";
import IBMLogo from '../Assets/IBM.jpg'
import AWSLogo from '../Assets/AWS.png'
import DPSALogo from '../Assets/DPSA.jpg'
import DDMLogo from '../Assets/DDMLogo.png';
import UNDP from '../Assets/UNDP.png'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ReactDOM from "react-dom";


const responsive = [
  { breakPoint: 1280, cardsToShow: 4 }, // this will be applied if screen size is greater than 1280px. cardsToShow will become 4.
  { breakPoint: 760, cardsToShow: 2 },
];

const Partners = () => {
  return (
<></>
  );
};

export default Partners;
