import React from "react";

import AboutBackgroundImage from "../Assets/i12.png";
import { BsFillPlayCircleFill } from "react-icons/bs";
import { FaBell } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { IoMdThumbsUp } from "react-icons/io";
import { HiUsers } from "react-icons/hi2";



const Features = () => {
  return (
    <div className="about-section-container">
      
     
      <div className="features-section-text-container">
        <p className="primary-subheading">Features</p>
        <h1 className="primary-heading"> Our App Features </h1>
    
        <p style={{marginBottom:"0px",marginTop:"50px"}} className="primary-text">
        
        <IoMdThumbsUp size={25} color="#DD883F" /> {"   "}  Two-Way Communication  </p>
          
        <p className="primary-text">
        Our app facilitates two-way communication between citizens and their local government. Users can request services, report incidents, and access information about training opportunities in their area.
        </p>
        <p style={{marginBottom:"0px",marginTop:"50px"}} className="primary-text">
        <FaBell  size={25} color="#DD883F" />
          {"   "}
          Geolocation
        </p>

        <p className="primary-text">
        Stay informed with geolocation-enabled instant messaging about what's happening in your community.
        </p>

        <p style={{marginBottom:"0px",marginTop:"50px"}} className="primary-text">
        <HiUsers size={25} color="#DD883F" />
          {"   "}
          Completely Free
        </p>

        <p className="primary-text">
        Our app is entirely free to download and use, helping you stay updated on what your local government is doing for you.
        </p>
       
       
       
      </div>
      <div className="about-section-image-container">
        <img src={AboutBackgroundImage} alt="" />
      </div>
    </div>
  );
};

export default Features;
