import React from "react";
import AboutBackgroundImage from "../Assets/Lamdba.png";


const Lamda = () => {
  return (
    <div className="about-section-container">
      
      <div className="features-section-text-container">
       
        <h1 className="lamda-primary-heading"> AWS Lambda </h1>

        <p className="lamda-primary-text">
        Saya Setona, a certified AWS Select Tier Partner, provides robust cloud computing services through the Let's Talk Platform, built for scalability, cost-efficiency, and serverless applications. Our integration with AWS Lambda delivers a flexible architecture and secure communication solutions for high-profile clients.
        </p>

        <p className="lamda-primary-text">
        Leveraging AWS's cloud infrastructure, the Let's Talk Platform ensures high availability, data security, and rapid scalability—even for municipalities and service departments with limited resources.
        With AWS Lambda, the platform efficiently manages high volumes of citizen interactions in real time, offering the flexibility to integrate seamlessly with other government systems.



        </p>

        <p className="lamda-primary-text">
        Together, the Let's Talk Platform and AWS Lambda create a cost-effective, efficient solution that enhances citizen engagement and improves service delivery at all levels of government.


        </p>

       
      </div>
      <div className="lamda-section-image-container">
        <img style={{width:"450px"}} src={AboutBackgroundImage} alt="" />
      </div>
    </div>
  );
};

export default Lamda;