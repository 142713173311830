import React from "react";
import ContactForm from "./ContactForm";

const Contact = () => {
  return (
    <div className="contact-page-wrapper">
      <h1 className="primary-heading">Have Question In Mind?</h1>
      <h1 className="primary-heading">Let Us Help You</h1>
      <div style={{marginTop:"50px"}}>
      <ContactForm />
      </div>
    </div>
  );
};

export default Contact;
