import React from "react";

import AboutBackgroundImage from "../Assets/iphone2.png";
import { BsFillPlayCircleFill } from "react-icons/bs";
import { FaRegDotCircle } from "react-icons/fa";



const Design = () => {

  const handleDownloadBela = () => {
    // Create a link element
    const link = document.createElement('a');
    link.href = '/Bela_Bela_Case_Study1.pdf';// Path to the PDF file
    link.setAttribute('download', 'Bela_Bela_Case_Study1.pdf');  // Specify a name for the downloaded file
    
    // Programmatically trigger the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  
  const handleDownloadNyandeni = () => {
    // Create a link element
    const link = document.createElement('a');
    link.href = `${process.env.PUBLIC_URL}/Nyandeni_Case_Study1.pdf`;  // Path to the PDF file
    link.setAttribute('download', 'Nyandeni_Case_Study1.pdf');  // Specify a name for the downloaded file.
    
    // Programmatically trigger the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="about-section-container">
      <div className="about-section-image-container">
        <img src={AboutBackgroundImage} alt="" />
      </div>
      <div className="about-section-text-container">
        <p className="primary-subheading">Design</p>
        <h1 className="primary-heading">
        We Designed an Interactive App Just for You
        </h1>
       
        <p className="primary-text">
        <FaRegDotCircle color="#DD883F" />
        Instant Geotagging: 
        Pinpoint your exact location on the map for seamless reporting.
        </p>
        <p className="primary-text">
        <FaRegDotCircle color="#DD883F" />
        Community Updates
        Stay informed with geo-targeted newsfeeds tailored to your area.

        </p>
        <p className="primary-text">
        <FaRegDotCircle color="#DD883F" />
        Streamlined Access to Government Services: 
        Experience quick and efficient connections to the services you need.
        </p>
        <div  className="about-buttons-container">
        <button className="secondary-button"
        onClick={handleDownloadBela}
        >
          Bela Bela
        </button>
        <div style={{marginLeft:"15px"}}></div>
          <button className="secondary-button" onClick={handleDownloadNyandeni}>
          Nyandeni 
          </button>
      </div>
      </div>
    </div>
  );
};

export default Design;
