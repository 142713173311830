import React from "react";
import { FaHandsHelping } from "react-icons/fa"; // Import Font Awesome icons
import { GiPointing  } from "react-icons/gi";
import { GoReport } from "react-icons/go";
const Work = () => {
  const workInfoData = [
    {
      icon: <GiPointing color="#DD883F" size={140} />, // Use icons instead of images
      title: "Pick a Service",
      text: "Choose the service you need assistance with.",
    },
    {
      icon: <GoReport color="#DD883F" size={140} />,
      title: "Report",
      text: "Submit your incident directly through the app.",
    },
    {
      icon: <FaHandsHelping color="#DD883F" size={140}/>,
      title: "Get the Help You Need",
      text: "Connect directly with a government official to resolve your issue.",
    },
  ];

  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
        <p className="primary-subheading">Work</p>
        <h1 className="primary-heading">How It Works</h1>
        <p className="primary-text">
          Our app works in three simple steps.
        </p>
      </div>
      <div className="work-section-bottom">
        {workInfoData.map((data) => (
          <div className="work-section-info" key={data.title}>
            <div className="info-boxes-icon-container">
              {data.icon} {/* Display the icon */}
            </div>
            <h2>{data.title}</h2>
            <p>{data.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Work;
