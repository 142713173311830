import React from "react";

import AboutBackgroundImage from "../Assets/Saly-26.png";
import { BsFillPlayCircleFill } from "react-icons/bs";

const Questions = () => {
  return (
    <div className="about-section-container">
      
     
      <div className="features-section-text-container">
        <p className="primary-subheading">Question</p>
        <h1 className="primary-heading">
        Have a Question?
        </h1>
        <p className="primary-text-question">
        Can we link our call-center administrators?
        </p>
        <p className="primary-text">
        Yes, administrators can seamlessly integrate with the application using existing infrastructure or systems.


        </p>

        <p className="primary-text-question">
        How do we safeguard your data?

        </p>
        <p className="primary-text">
        We are fully POPIA compliant, as all our servers are hosted securely on Amazon Web Services (AWS). Data transmitted through the platform is accessible only to the intended end users, ensuring robust privacy and security.
        </p>

        <p className="primary-text-question">
        How do we protect against job losses in the country?
        </p>
        <p className="primary-text">
        The app is designed to enhance government services by leveraging technology to improve efficiency and transparency. It empowers officials to assist citizens more effectively, supporting the workforce rather than replacing it. This fosters better communication between the government and the public, creating opportunities for growth and collaboration.“
        </p>
     
        
      </div>

      <div className="about-section-image-container">
        <img src={AboutBackgroundImage} alt="" />
      </div>
    </div>
  );
};

export default Questions;
