import React from "react";
import { FaServer } from 'react-icons/fa';
import { FaDollarSign } from 'react-icons/fa';
import { FaChartLine } from 'react-icons/fa';
import { FaSitemap } from 'react-icons/fa';

const LambdaFeatures = () => {
  const LambdaFeaturesInfoData = [
    {
      icon: <FaServer color="#d88613" size={140} />, // Use icons instead of images
      title: "Zero Server Management",
      text: "AWS Lambda handles infrastructure tasks like provisioning, scaling, and maintenance, letting you focus entirely on development.",
    },
    {
      icon: <FaSitemap color="#d88613" size={140} />,
      title: "AWS Integration",
      text: "Lambda integrates seamlessly with over 200 AWS services, enabling powerful, serverless architectures for complex workflows.",
    },
    {
      icon: <FaDollarSign color="#d88613" size={140}/>,
      title: "Cost Efficiency",
      text: "Pay only for the compute time used, with billing in milliseconds and a generous free tier, making it ideal for cost-conscious projects.",
    },
    {
      icon: <FaChartLine color="#d88613" size={140}/>,
      title: "Automatic Scaling",
      text: "Lambda scales instantly to meet demand, ensuring consistent performance during traffic spikes without overprovisioning.",
    },
  ];

  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
      
        <h1 className="primary-heading">Why use Lambda?</h1>
       
      </div>
      <div className="work-section-bottom">
        {LambdaFeaturesInfoData.map((data) => (
          <div className="work-section-info" key={data.title}>
            <div className="info-boxes-icon-container">
              {data.icon} {/* Display the icon */}
            </div>
            <h2>{data.title}</h2>
            <p>{data.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LambdaFeatures;
