import React from "react";
import AboutBackgroundImage from "../Assets/Lamdba.png";
import awspartner from "../Assets/awspatner.png";


const AWSPartner = () => {
  return (


<div style={{marginTop:"150px"}} className="lambda-section-container">

<div className="lamda-section-image-container">
<img src={awspartner} alt="awspartner" />   
</div>
<div className="lamda-feature-section-text-container">
 
  <p >
  By harnessing the full potential of AWS Lambda, we design solutions that optimize infrastructure, reduce operational costs, and deliver unmatched efficiency and performance.
At Saya Setona, we are committed to delivering innovative and cost-effective cloud computing solutions. Leveraging AWS Lambda’s powerful capabilities, we facilitate seamless migrations and build applications designed to minimize expenses while maximizing productivity.
With our technical expertise, your serverless applications will exceed industry standards, empowering your business with a substantial competitive edge.  </p> 
</div>

</div>
  );
};

export default AWSPartner;